<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="46.722" height="49.699" viewBox="0 0 46.722 49.699">
    <g id="Сгруппировать_9099" data-name="Сгруппировать 9099" transform="translate(-491.664 -209.526)">
      <path id="Контур_7753" data-name="Контур 7753" d="M515.851,259.165l-4.715-2.2a.642.642,0,0,1-.372-.583V220.639h1.288v35.333L516.4,258Z" :fill="color"/>
      <path id="Контур_7754" data-name="Контур 7754" d="M516.123,259.225a.642.642,0,0,1-.644-.644V238.1h1.288v19.6l20.331-6.45v-34.1l-20.785,6.432a.645.645,0,0,1-.476-.038l-4.715-2.331a.644.644,0,0,1,.1-1.193l20.343-6.261a.645.645,0,0,1,.359-.006l2.754.749-.338,1.243-2.574-.7-18.618,5.73,3.034,1.5,21.374-6.615a.644.644,0,0,1,.834.616v35.441a.643.643,0,0,1-.449.614l-21.62,6.859A.644.644,0,0,1,516.123,259.225Z" :fill="color"/>
      <rect id="Прямоугольник_3146" data-name="Прямоугольник 3146" width="1.288" height="3.435" transform="translate(515.479 231.66)" :fill="color"/>
      <rect id="Прямоугольник_3147" data-name="Прямоугольник 3147" width="1.288" height="4.294" transform="translate(515.479 225.281)" :fill="color"/>
      <path id="Контур_7755" data-name="Контур 7755" d="M521.155,236.064a.645.645,0,0,1-.644-.644v-8.1a.645.645,0,0,1,.454-.616l13.193-4.082a.644.644,0,0,1,.835.615v8.1a.644.644,0,0,1-.454.615l-13.193,4.083A.661.661,0,0,1,521.155,236.064Zm.645-8.267v6.749l11.905-3.684v-6.749Z" :fill="color"/>
      <path id="Контур_7756" data-name="Контур 7756" d="M506.242,256.982l-4.715-2.2a.644.644,0,0,1-.372-.584V218.457h1.288V253.79l4.343,2.025Z" :fill="color"/>
      <path id="Контур_7757" data-name="Контур 7757" d="M506.514,221.433a.652.652,0,0,1-.286-.067l-4.715-2.332a.644.644,0,0,1,.1-1.193l20.343-6.26a.645.645,0,0,1,.359-.006l2.754.749-.338,1.243-2.574-.7-18.618,5.73,3.034,1.5,2.271-.7.381,1.23-2.517.779A.649.649,0,0,1,506.514,221.433Z" :fill="color"/>
      <path id="Контур_7758" data-name="Контур 7758" d="M506.514,257.043a.644.644,0,0,1-.644-.645V235.919h1.288v19.6l4.194-1.33.389,1.228-5.033,1.6A.615.615,0,0,1,506.514,257.043Z" :fill="color"/>
      <rect id="Прямоугольник_3148" data-name="Прямоугольник 3148" width="1.288" height="3.435" transform="translate(505.87 229.478)" :fill="color"/>
      <rect id="Прямоугольник_3149" data-name="Прямоугольник 3149" width="1.288" height="4.294" transform="translate(505.87 223.098)" :fill="color"/>
      <path id="Контур_7759" data-name="Контур 7759" d="M496.75,254.956l-4.714-2.2a.645.645,0,0,1-.372-.584V216.431h1.288v35.333l4.343,2.025Z" :fill="color"/>
      <path id="Контур_7760" data-name="Контур 7760" d="M497.023,219.407a.649.649,0,0,1-.286-.067l-4.714-2.332a.644.644,0,0,1,.1-1.193l20.342-6.26a.636.636,0,0,1,.359-.006l2.753.749-.338,1.243-2.574-.7-18.618,5.729,3.034,1.5,2.659-.823.381,1.231-2.9.9A.649.649,0,0,1,497.023,219.407Z" :fill="color"/>
      <path id="Контур_7761" data-name="Контур 7761" d="M497.023,255.016a.644.644,0,0,1-.645-.644V233.893h1.289v19.6l3.937-1.249.39,1.228-4.777,1.515A.618.618,0,0,1,497.023,255.016Z" :fill="color"/>
      <rect id="Прямоугольник_3150" data-name="Прямоугольник 3150" width="1.288" height="3.435" transform="translate(496.378 227.452)" :fill="color"/>
      <rect id="Прямоугольник_3151" data-name="Прямоугольник 3151" width="1.288" height="4.294" transform="translate(496.378 221.072)" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBooks',
  props: {
    width: {
      type: String,
      default: '42.305'
    },
    height: {
      type: String,
      default: '42.305'
    },
    color: {
      type: String,
      default: 'var(--color__primary-light)'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
