<template>
  <div>
    <Breadcrumbs>
      {{ $route.meta.title }}
    </Breadcrumbs>
    <div class="form-container pb-15 px-5">
      <h1 class="text-heading--prm text-center pt-10 pb-8">
        {{ $t("downloadDocuments.downloadDocuments") }}
      </h1>
      <v-container style="cursor: pointer">
        <v-row
          v-for="item in items"
          :key="item.id"
          @click="routerLink(item.link)"
          class="bg-white mb-3 rounded-10 --border-light-gray border-medium"
        >
          <v-col class="col-3 text-center">
            <IconBooks v-if="item.id === 1" />
            <IconOpenBook v-if="item.id === 2" />
          </v-col>
          <v-col
            v-html="item.text"
            class="text-title --medium-gray font-weight-medium pl-0 d-flex flex-column justify-center"
          >
          </v-col>
        </v-row>
      </v-container>

      <h1 class="text-heading--c-prm text-center pt-10 pb-8">
        カテゴリー
        <!--{{ $t('downloadDocuments.downloadDocuments') }}-->
      </h1>

      <div v-for="(doc, index) in docs" :key="doc.id">
        <h1
          v-if="showTitle(index)"
          class="text-heading--c-prm text-center pt-4 pb-2"
        >
          {{ doc.docType.name }}
        </h1>
        <DocumentBlock :url="doc.url">
          {{ doc.fileName }}
        </DocumentBlock>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import DocumentBlock from './components/DocumentBlock'
import IconBooks from '@/components/icons/IconBooks'
import IconOpenBook from '../../components/icons/IconOpenBook'

export default {
  name: 'DownloadDocuments',
  components: {
    IconOpenBook,
    Breadcrumbs,
    IconBooks,
    DocumentBlock
  },
  computed: {
    docs () {
      return this.$store.state.documents.otherPublicDocList
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('getDocuments', {
          apollo: this.$apollo
        })
      })
    })
  },
  data () {
    return {
      items: [
        {
          id: 1,
          text: 'バケーションライフ<br>バックナンバー',
          link: '/home/download-documents/vacation-life-back-number'
        }
      ],
      pointSelected: 'sharing-point',
      remainingSharingPoints: 32000,
      remainingFlexPoints: 35000
    }
  },
  methods: {
    showTitle (index) {
      if (index === 0) return true
      if (this.docs[index - 1].docType.name === this.docs[index].docType.name) {
        return false
      }
      return true
    },
    async routerLink (link) {
      if (link === null) {
        await this.userGuideList(0)
      } else {
        await this.$router.push(link)
      }
    },
    async userGuideList (index) {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          const res = await this.$store.dispatch('getUserGuideList', {
            apollo: this.$apollo
          })
          window.open(res[index].url, '_blank')
        })
      })
    }
  }
}
</script>
