<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="46.022" height="40.772" viewBox="0 0 46.022 40.772">
    <g id="Сгруппировать_9100" data-name="Сгруппировать 9100" transform="translate(-346.13 -107.942)">
      <path id="Контур_7762" data-name="Контур 7762" d="M366.548,147.278a.658.658,0,0,1-.139-.015l-17.316-3.631a3.745,3.745,0,0,1-2.963-3.649V113.894a.673.673,0,0,1,.813-.66l19.743,4.14a.674.674,0,0,1,.536.661V146.6a.674.674,0,0,1-.674.675Zm-19.069-32.553v25.258a2.389,2.389,0,0,0,1.891,2.328l16.5,3.461V118.583Z" :fill="color"/>
      <path id="Контур_7763" data-name="Контур 7763" d="M371.734,147.278a.674.674,0,0,1-.674-.675V118.035a.674.674,0,0,1,.536-.661l19.743-4.14a.673.673,0,0,1,.813.66v26.089a3.745,3.745,0,0,1-2.963,3.649l-17.316,3.631A.657.657,0,0,1,371.734,147.278Zm.675-28.695v27.189l16.5-3.461a2.39,2.39,0,0,0,1.891-2.328V114.725Z" :fill="color"/>
      <path id="Контур_7764" data-name="Контур 7764" d="M369.141,148.714a3.272,3.272,0,0,1-3.268-3.268V119.624a3.268,3.268,0,0,1,6.536,0v25.822A3.272,3.272,0,0,1,369.141,148.714Zm0-31.008a1.921,1.921,0,0,0-1.919,1.918v25.822a1.919,1.919,0,1,0,3.838,0V119.624A1.921,1.921,0,0,0,369.141,117.706Z" :fill="color"/>
      <path id="Контур_7765" data-name="Контур 7765" d="M368.86,117.645l-17.448-7.978v5.052h-1.349v-6.1a.675.675,0,0,1,.955-.614l18.4,8.414Z" :fill="color"/>
      <path id="Контур_7766" data-name="Контур 7766" d="M369.422,117.645l-.562-1.228,18.4-8.414a.675.675,0,0,1,.955.614v6.1H386.87v-5.052Z" :fill="color"/>
      <rect id="Прямоугольник_3153" data-name="Прямоугольник 3153" width="2.063" height="1.35" transform="translate(384.912 119.465) rotate(-11.833)" :fill="color"/>
      <rect id="Прямоугольник_3154" data-name="Прямоугольник 3154" width="1.716" height="1.35" transform="translate(382.067 120.061) rotate(-11.833)" :fill="color"/>
      <rect id="Прямоугольник_3155" data-name="Прямоугольник 3155" width="4.333" height="1.35" transform="translate(376.383 121.253) rotate(-11.833)" :fill="color"/>
      <rect id="Прямоугольник_3156" data-name="Прямоугольник 3156" width="10.778" height="1.35" transform="translate(376.382 126.136) rotate(-11.833)" :fill="color"/>
      <path id="Контур_7767" data-name="Контур 7767" d="M356.963,125.26a.674.674,0,0,1-.479-.2l-1.639-1.653-1.522,1.249a.674.674,0,0,1-1.1-.521v-8.963h1.35v7.536l.893-.734a.676.676,0,0,1,.908.047l.918.926v-7.1h1.349v8.74a.675.675,0,0,1-.675.675Z" :fill="color"/>
    </g>
  </svg>

</template>

<script>
export default {
  name: 'IconOpenBook',
  props: {
    width: {
      type: String,
      default: '42.305'
    },
    height: {
      type: String,
      default: '42.305'
    },
    color: {
      type: String,
      default: 'var(--color__primary-light)'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
